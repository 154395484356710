import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlertsAction,
  modifyAlertStatusAction,
  deleteAlertsAction,
} from "../actions";
import {
  getData,
  getTypes,
  getIsLoading,
  getLocale,
  getDateFormat,
} from "../selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useAlerts = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAlertsAction());
  }, [dispatch])

  const types = useSelector(getTypes);
  const data = useSelector(getData);
  const isLoading = useSelector(getIsLoading);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);

  const [selectedCodes, setSelectedCodes] = useState([]);
  const [gridDialogIsOpen, setGridDialogIsOpen] = useState(false);
  const [gridData, setGridData] = useState(null);
  const [viewUnread, setViewUnread] = useState(true);
  const [typeSelected, setTypeSelected] = useState('');

  const handleChangeSelectedCodes = (code) => {
    if (typeof code === "string") {
      // si ya lo incluye, sacarlo
      if (selectedCodes?.includes(code)) {
        const auxSelectedCodes = [...selectedCodes];
        const codeIndex = auxSelectedCodes.indexOf(code);
        if (codeIndex > -1) {
          auxSelectedCodes.splice(codeIndex, 1);
          setSelectedCodes(auxSelectedCodes);
        }
        return;
      }

      setSelectedCodes((prev) => [...prev, code]);
    } else {
      // Tengo un array

      //si ya lo incluye, sacarlo
      if (selectedCodes?.some(r => code.includes(r))) {
        const auxSelectedCodes = [...selectedCodes];
        const result = auxSelectedCodes.filter(item => !code.includes(item));
        setSelectedCodes(result);
        return;
      }

      setSelectedCodes((prev) => [...prev, ...code]);
    }

  }

  const handleOpenGridDialog = (data) => {
    setGridData(data.visualizationGrid);
    setGridDialogIsOpen(true);
  }

  const handleCloseGridDialog = () => {
    setGridDialogIsOpen(false);
    setGridData(null);
  }

  const handleModifyStatus = (code) => dispatch(modifyAlertStatusAction(code));

  const handleChangeViewUnread = (value) => setViewUnread(value);
  const handleSelectType = (type) => setTypeSelected(type);

  const handleOpenDeleteDialog = (code) => {
    const msg = (() => {
      if (code) return '¿Desea eliminar la alerta?';
      if (selectedCodes.length) return '¿Desea eliminar las alertas seleccionadas?';
      return '¿Desea eliminar todas las alertas y avisos leídos?'
    })();

    dispatch(openDialogAction({
      msg,
      title: "Atención",
      onConfirm: () => handleDelete(code),
    }));
  }

  const handleDelete = (code) => {
    const codesToUse = code ? [code] : selectedCodes;
    dispatch(deleteAlertsAction(codesToUse))
      .then((resp) => {
        if (resp.status === 200) {
          setSelectedCodes([]);
        }
      })
  };

  const state = {
    selectedCodes,
    gridDialogIsOpen,
    gridData,
    viewUnread,
    typeSelected,
  };

  const handlers = {
    handleModifyStatus,
    handleChangeViewUnread,
    handleSelectType,
    handleOpenDeleteDialog,
    handleOpenGridDialog,
    handleCloseGridDialog,
    handleChangeSelectedCodes,
  }


  return {
    isLoading,
    data,
    types,
    dateFormat,
    locale,
    state,
    handlers,
  }
}

export default useAlerts;