import React, { useState, useEffect, useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid, Typography, ListItem, List, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from '@material-ui/core/TablePagination';
import AlertItem from "./alertItem";

const CustomList = withStyles({
    root: {
        maxHeight: "calc(100vh - 207px)",
        overflow: "auto",
        position: "relative",
    },
})(List);

const AlertList = (props) => {

    const {
        alerts,
        handleOpenGridDialog,
        handleModifyStatus,
        handleDelete,
        color,
        gridTheme,
        isLoading,
        viewUnread,
        filteredAlertType,
        selectedCodes,
        handleChangeSelectedCodes,
    } = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        setPage(0);
    }, [viewUnread, filteredAlertType])

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const formatLabelDisplayedRows = ({ from, to, count }) => {
        const label = count !== -1 ? count : getLocalizedString("moreThan") + " " + to;
        return `${from}-${to} ${getLocalizedString("of")} ${label}`;
    }

    const filteredAlerts = useMemo(() => {
        return alerts.filter((alert) => {
            if (viewUnread && alert.isRead) return false;
            if (filteredAlertType && alert.alertClass !== filteredAlertType) return false;
            return true;
        });
    }, [alerts, viewUnread, filteredAlertType])

    const allElements = filteredAlerts
        .map((item => item.code));

    // Seteo la pagina despues de borrar todo
    if (allElements.length === 0 && page !== 0) {
        setPage(0);
    }

    return (
        <section style={{ marginBottom: 25 }}>
            <Grid container item xs={12}>
                <Grid item xs={1}>
                    {
                        allElements.length > 0 &&
                        <Checkbox
                            onChange={() => handleChangeSelectedCodes(allElements)}
                            checked={selectedCodes.sort().toString() === allElements.sort().toString()}
                            style={{ paddingLeft: 25 }}
                        />
                    }
                </Grid>
                <Grid item xs={11}>
                    <TablePagination
                        component="div"
                        className="whiteText"
                        count={filteredAlerts.length}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        backIconButtonText={getLocalizedString("previousPage")}
                        nextIconButtonText={getLocalizedString("nextPage")}
                        labelRowsPerPage={getLocalizedString("labelRowsPerPage")}
                        labelDisplayedRows={formatLabelDisplayedRows}
                        rowsPerPageOptions={[10, 20, 30]}
                    />
                </Grid>
            </Grid>
            {
                filteredAlerts
                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                    .map((item) => {
                        return (
                            <AlertItem
                                key={item.code}
                                data={item}
                                color={color}
                                gridTheme={gridTheme}
                                handleOpenGridDialog={handleOpenGridDialog}
                                handleModifyStatus={handleModifyStatus}
                                handleDelete={handleDelete}
                                selectedCodes={selectedCodes}
                                handleChangeSelectedCodes={handleChangeSelectedCodes}
                            />
                        )
                    })
            }
            {
                !isLoading && filteredAlerts.length === 0 &&
                <CustomList className="list-round-container">
                    <ListItem>
                        <Typography variant="h6" id={"toolbar-title"}>
                            {getLocalizedString("noAlertsOrNotification")}
                        </Typography>
                    </ListItem>
                </CustomList>
            }
        </section>
    )
}

export default AlertList;